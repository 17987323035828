























import LazyHydrate from 'vue-lazy-hydration';
import {
  defineComponent,
  useRoute,
  useRouter,
  computed
} from '@nuxtjs/composition-api';
import { useUiState } from '~/composables';
import AppHeader from '~/components/AppHeader.vue';
import IconSprite from '~/components/General/IconSprite.vue';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';

export default defineComponent({
  name: 'DefaultLayout',
  head() {
    return {
      link: [
        { rel: 'preconnect', href: process.env.VSF_MAGENTO_BASE_URL },
        { rel: 'dns-prefetch', href: process.env.VSF_MAGENTO_BASE_URL },
      ],
    }
  },
  components: {
    LoadWhenVisible,
    LazyHydrate,
    AppHeader,
    IconSprite,
    AppFooter: () => import(/* webpackPrefetch: true */ '~/components/AppFooter.vue'),
    CartSidebar: () => import(/* webpackPrefetch: true */ '~/modules/checkout/components/CartSidebar.vue'),
    LoginModal: () => import(/* webpackPrefetch: true */ '~/modules/customer/components/LoginModal/LoginModal.vue'),
    Notification: () => import(/* webpackPrefetch: true */ '~/components/Notification.vue'),
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const {
      isLoginModalOpen,
      toggleLoginModal,
      isCartSidebarOpen
    } = useUiState();

    const isContentCenterClass = computed(() => route.value.path === '/checkout/payment-processing' ? 'payment-resolver-class' : '')

    // Mindbox
    router.afterEach((to) => {
      if (to.name) {
        if (window['PopMechanic'] && window['PopMechanic'].update) {
          window['PopMechanic'].update();
        }
      }
    });

    return {
      isCartSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
      route,
      isContentCenterClass
    };
  },
  jsonld() {
    return {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      'name': 'Togas Online',
      'description': 'Gorgeous home textiles, innovative sleep products and designer home goods available to buy online.',
      'url': 'https://togas.com/',
      'logo': 'https://togas.com/int/logo.png',
      'department': [
        {
          '@type': 'Store',
          'name': 'Europe Customer Service',
          'telephone': '+302109606616',
          'openingHoursSpecification': [
            {
              '@type': 'OpeningHoursSpecification',
              'dayOfWeek': [
                'Monday',
                'Wednesday',
              ],
              'opens': '10:00',
              'closes': '18:00'
            },
            {
              '@type': 'OpeningHoursSpecification',
              'dayOfWeek': [
                'Tuesday',
                'Thursday',
                'Friday'
              ],
              'opens': '10:00',
              'closes': '21:00'
            },
            {
              '@type': 'OpeningHoursSpecification',
              'dayOfWeek': [
                'Saturday'
              ],
              'opens': '10:00',
              'closes': '16:00'
            }
          ]
        },
        {
          '@type': 'Store',
          'name': 'USA Customer Service',
          'telephone': '+12123002876',
          'openingHoursSpecification': [
            {
              '@type': 'OpeningHoursSpecification',
              'dayOfWeek': [
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday'
              ],
              'opens': '10:00',
              'closes': '18:00'
            },
            {
              '@type': 'OpeningHoursSpecification',
              'dayOfWeek': [
                'Sunday'
              ],
              'opens': '11:00',
              'closes': '17:00'
            },
          ]
        },
        {
          '@type': 'Store',
          'name': 'Middle East Customer Service',
          'telephone': '+971(0)43200773',
          'openingHoursSpecification': [
            {
              '@type': 'OpeningHoursSpecification',
              'dayOfWeek': [
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
                'Sunday'
              ],
              'opens': '10:00',
              'closes': '21:00'
            }
          ]
        }
      ]
    };
  },
});
