<template>
  <client-only>
    <div id="esthira">
      <section class="hero">
        <video
          class="hero__video mobile-only"
          width="100%"
          height="100%"
          muted
          autoplay
          playsinline
          loop
        >
          <source
            type="video/mp4; codecs=&quot;avc1.42E01E, mp4a.40.2&quot;"
            :src="require(`~/static/esthira/esfira-mob.mp4`)"
          />
          <track src="assets/sub.vtt" kind="captions" srclang="en">
        </video>
        <video
          class="hero__video desktop-only"
          width="100%"
          height="100%"
          muted
          autoplay
          playsinline
          loop
        >
          <source
            type="video/mp4; codecs=&quot;avc1.42E01E, mp4a.40.2&quot;"
            :src="require(`~/static/esthira/esfira-desk.mp4`)"
          />
          <track src="assets/sub.vtt" kind="captions" srclang="en">
        </video>
        <div class="hero__title-wrap">
          <h2 class="hero__title">Esthira: The Art of Luxury</h2>
          <span class="hero__subtitle">Fall-Winter Collection 2024/2025</span>
        </div>
        <div class="hero__link-wrapper">
          <SfLink
            class="hero__link"
            :link="localePath('sensotex')"
          >
            <img
              class="hero__logo"
              :src="require(`~/static/esthira/sensotex-logo.svg`)"
            >
          </SfLink>
        </div>
      </section>
      <section class="persia">
        <div class="persia__title-wrap">
          <h2 class="persia__title">The Luxury of <br class="desk-only"> Ancient Persia</h2>
          <span class="persia__subtitle">
            The Esthira was influenced by Ancient Persian art — majestic and beautiful palaces,
            embroidered fabrics, and exquisite combinations of antique gold and silver tones.
          </span>
        </div>
        <div class="persia__image-wrapper">
          <picture class="img-block left-image">
            <source
              :srcset="require(`~/static/esthira/persia/left-xxl${isMe ? `-me`: ''}.webp`)"
              media="(min-width: 1000px)"
            />
            <source
              :srcset="require(`~/static/esthira/persia/left-m${isMe ? `-me`: ''}.webp`)"
              media="(min-width: 700px) and (max-width: 999px)"
            />
            <img
              :src="require(`~/static/esthira/persia/left-s${isMe ? `-me`: ''}.webp`)"
              alt=""
            />
          </picture>
          <picture class="img-block right-image">
            <source
              :srcset="require(`static/esthira/persia/right-xxl.webp`)"
              media="(min-width: 1240px)"
            />
            <source
              :srcset="require(`~/static/esthira/persia/right-m.webp`)"
              media="(min-width: 1000px) and (max-width: 1239px)"
            />
            <source
              :srcset="require(`~/static/esthira/persia/right-m.webp`)"
              media="(min-width: 700px) and (max-width: 999px)"
            />
            <img
              :src="require(`~/static/esthira/persia/right-s.webp`)"
              alt=""
            />
          </picture>
        </div>
      </section>
      <section class="oriental">
        <div class="oriental__title-wrap--mob">
          <h2 class="oriental__title--mob oriental__title">Oriental Patterns</h2>
          <p class="oriental__subtitle--mob oriental__subtitle">
            The collection captures the beauty and intricacy of the paisley pattern, which used to be woven into
            shimmering silk. Togas artisans have skillfully recreated the delicate sheen of silk fabric and gold thread
            trim, making it feel as though you can almost touch its texture.
          </p>
        </div>

        <div class="oriental__image-container">
          <div class="bg-container">
            <picture class="img-block">
              <source
                :srcset="require(`~/static/esthira/oriental/oriental-bg-xxl.webp`)"
                media="(min-width: 1480px)"
              />
              <source
                :srcset="require(`~/static/esthira/oriental/oriental-bg-xl.webp`)"
                media="(min-width: 1240px)  and (max-width: 1479px)"
              />
              <source
                :srcset="require(`~/static/esthira/oriental/oriental-bg-l.webp`)"
                media="(min-width: 1000px) and (max-width: 1239px)"
              />
              <source
                :srcset="require(`~/static/esthira/oriental/oriental-bg-m.webp`)"
                media="(min-width: 700px) and (max-width: 999px)"
              />
              <img
                :src="require(`~/static/esthira/oriental/oriental-bg-m.webp`)"
                alt=""
              />
            </picture>
            <div class="container">
              <div class="oriental__pillow">
                <picture class="img-block">
                  <source
                    media="(min-width: 1480px)"
                    :srcset="require(`~/static/esthira/oriental/pillow-xxl.webp`)"
                  />
                  <source
                    media="(min-width: 1240px)  and (max-width: 1479px)"
                    :srcset="require(`~/static/esthira/oriental/pillow-xxl.webp`)"
                  />
                  <source
                    media="(min-width: 1000px) and (max-width: 1239px)"
                    :srcset="require(`~/static/esthira/oriental/pillow-xxl.webp`)"
                  />
                  <source
                    media="(min-width: 700px) and (max-width: 999px)"
                    :srcset="require(`~/static/esthira/oriental/pillow-xxl.webp`)"
                  />
                  <img
                    :src="require(`~/static/esthira/oriental/pillow-xxl.webp`)"
                    alt=""
                  />
                </picture>
              </div>
              <div class="oriental__title-wrap">
                <h2 class="oriental__title">Oriental Patterns</h2>
                <p class="oriental__subtitle">
                  The collection captures the beauty and intricacy of the paisley pattern,
                  which used to be woven into shimmering silk.
                  Togas artisans have skillfully recreated the delicate sheen of silk fabric and gold thread trim,
                  making it feel as though you can almost touch its texture.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="art">
        <div class="art__title-wrap">
          <h2 class="art__title">The Art of Ancient Artists <br class="art__br"> in a New Interpretation</h2>
          <p class="art__subtitle">
            The Esthira Collections represents Eastern luxury in a modern interpretation.
            The legacy of Ancient Persian art is transformed into contemporary forms,
            bridging past and present.
          </p>
          <SfLink
            class="desk-only art__link"
            :link="decodeURIComponent(localePath('/collections/bedding-collections/esthira'))"
          >
            View the collection
          </SfLink>
        </div>
        <carousel
          :settings="artCarouselSettings"
          :container-class="'art-carousel'"
          :carousel-items-qty="6"
          is-show-navigation
          white-bullets
        >
          <SfLink
            v-for="(artLink, i) in artLinks"
            :key="i"
            class="swiper-slide"
            :link="decodeURIComponent(localePath(isMe ? artLink.linkMe : artLink.linkInt))"
            target="_blank"
          >
            <img
              class="img-block"
              :src="require(`~/static/esthira/art/art-slide-${i + 1}-xl${isMe && (i + 1) === 4 ? '-me' : ''}.webp`)"
            >
          </SfLink>
        </carousel>
        <SfLink
          class="mobile-only art__link"
          :link="decodeURIComponent(localePath('/collections/bedding-collections/esthira'))"
        >
          View the collection
        </SfLink>
      </section>
      <section class="fabric">
        <div class="fabric__title-wrapper">
          <h2 class="fabric__title">Exceptional <br class="desk-only"> Softness of Fabric</h2>
          <span class="fabric__subtitle">
                  The silky SENSOTEX® fabric is a unique development by Togas.
                  This material is made from eucalyptus fibers,
                  and is infused with Kefalis solution from Ionian Sea algae extract for extra softness.
                  This bedding provides comfort year-round, offering a sense of coolness on warm nights.
                  The soft SENSOTEX® is designed for people with sensitive skin.
                </span>
        </div>
        <img
          class="fabric__logo"
          :src="require(`~/static/esthira/sensotex-logo.svg`)"
        >
        <carousel
          :settings="fabricCarouselSettings"
          :container-class="'fabric-carousel'"
          :carousel-items-qty="3"
          white-bullets
        >
          <div
            v-for="i in 3"
            :key="i"
            class="swiper-slide"
          >
            <picture class="img-block">
              <source
                :srcset="require(`~/static/esthira/fabric/fabric-slide-${i}-xxl${isMe && i === 1 ? '-me' : ''}.webp`)"
                media="(min-width: 1240px)"
              />
              <source
                :srcset="require(`~/static/esthira/fabric/fabric-slide-${i}-l${isMe && i === 1 ? '-me' : ''}.webp`)"
                media="(min-width: 1000px) and (max-width: 1239px)"
              />
              <source
                :srcset="require(`~/static/esthira/fabric/fabric-slide-${i}-m${isMe && i === 1 ? '-me' : ''}.webp`)"
                media="(min-width: 700px) and (max-width: 999px)"
              />
              <img
                :src="require(`~/static/esthira/fabric/fabric-slide-${i}-s${isMe && i === 1 ? '-me' : ''}.webp`)"
                alt=""
              />
            </picture>
          </div>
        </carousel>
        <div class="fabric__pagination"></div>
      </section>
      <section class="grid-slider">
        <carousel
          :settings="gridCarouselSettings"
          :container-class="'grid-carousel'"
          :carousel-items-qty="2"
          white-bullets
        >
          <div class="swiper-slide">
            <div class="grid-slider__wrapper">
              <div
                v-for="i in 5"
                :key="i"
                :class="[`grid-slider__wrapper-image--${i}`]"
              >
                <img
                  class="grid-slider__wrapper-image"
                  :src="require(`~/static/esthira/grid/${i}${isMe && i === 1 ? '-me' : ''}.jpg`)"
                  alt=""
                >
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="grid-slider__wrapper">
              <div
                v-for="i in 4"
                :key="i"
                :class="[`grid-slider__wrapper-image--${i+5}`]"
              >
                <img
                  class="grid-slider__wrapper-image"
                  :src="require(`~/static/esthira/grid/${i+5}${isMe && i+5 === 9? '-me' : ''}.jpg`)"
                  alt=""
                >
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="grid-slider__wrapper">
              <div
                v-for="i in 5"
                :key="i"
                :class="[`grid-slider__wrapper-image--${i+9}`]"
              >
                <img
                  class="grid-slider__wrapper-image"
                  :src="require(`~/static/esthira/grid/${i+9}.jpg`)"
                  alt=""
                >
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="grid-slider__wrapper">
              <div
                v-for="i in 4"
                :key="i"
                :class="[`grid-slider__wrapper-image--${i+14}`]"
              >
                <img
                  class="grid-slider__wrapper-image"
                  :src="require(`~/static/esthira/grid/${i+14}.jpg`)"
                  alt=""
                >
              </div>
            </div>
          </div>
        </carousel>
        <div class="grid-carousel-swiper-button-prev swiper-button-prev"></div>
        <div class="grid-carousel-swiper-button-next swiper-button-next"></div>
      </section>
    </div>
  </client-only>
</template>
<script>
import {
  defineComponent,
  ref,
  onMounted
} from '@nuxtjs/composition-api';

import {
  SfHeading,
  SfLink
} from '~/components';

import { useWebsiteCode } from '~/composables';

export default defineComponent({
  name: 'Esthira',
  components: {
    SfHeading,
    SfLink,
    Carousel: () => import('~/components/togas/Carousel.vue'),
  },
  setup() {
    const { isMe } = useWebsiteCode();

    const artLinks = [
      {
        linkInt: '/throw-nanmias-101500257',
        linkMe: '/1019-00375-conf',
      },
      {
        linkInt: '/1002-00649',
        linkMe: '/1019-00375-conf',
      },
      {
        linkInt: '/1006-02619-conf?MjI3=Y29uZmlndXJhYmxlLzIyNy84NjI%3D',
        linkMe: '/1019-00375-conf',
      },
      {
        linkInt: '/1006-01542-conf?MjI3=Y29uZmlndXJhYmxlLzIyNy84NjI%3D',
        linkMe: '/1019-00375-conf',
      },
      {
        linkInt: '/029-00247-conf?MjYy=Y29uZmlndXJhYmxlLzI2Mi8zNjU4',
        linkMe: '/1019-00375-conf',
      },
      {
        linkInt: '/1001-00144',
        linkMe: '/1019-00375-conf',
      },
    ];
    const artCarouselSettings = ref({
      loop: true,
      speed: 500,
      centeredSlides: false,
      breakpoints: {
        1480: {
          slidesPerView: 'auto',
          loopedSlides: 16,
          slidesPerGroup: 1,
          centeredSlides: false,
          spaceBetween: 8,
        },
        1024: {
          slidesPerView: 1.6,
          slidesPerGroup: 1,
          centeredSlides: false,
          spaceBetween: 8,
        },
        768: {
          slidesPerView: 1.4,
          slidesPerGroup: 1,
          centeredSlides: true,
        },
        480: {
          slidesPerView: 1.4,
          slidesPerGroup: 1,
          centeredSlides: true,
        },
        320: {
          slidesPerView: 1.4,
          slidesPerGroup: 1,
          centeredSlides: true,
        },
        0: {
          slidesPerView: 1.4,
          slidesPerGroup: 1,
          centeredSlides: true,
        },
      },
    });

    const fabricCarouselSettings = ref({
      loop: true,
      pagination: {
        el: '.fabric__pagination',
      },
      speed: 1000,
      autoplay: {
        delay: 3000,
      },
      breakpoints: {
        1240: {
          slidesPerView: 1,
          direction: 'vertical'
        },
        1024: {
          slidesPerView: 1,
          direction: 'vertical'
        },
        1000: {
          slidesPerView: 1,
          direction: 'vertical'
        },
        768: {
          slidesPerView: 1,
          direction: 'horizontal'
        },
        480: {
          slidesPerView: 1,
          direction: 'horizontal'
        },
      },
    });

    const gridCarouselSettings = ref({
      loop: true,
      breakpoints: {
        1240: {
          slidesPerView: 2,
          slidesPerGroup: 2,
          spaceBetween: 1,
        },
        1024: {
          slidesPerView: 2,
          slidesPerGroup: 2,
          spaceBetween: 1,
        },
        1000: {
          slidesPerView: 2,
          slidesPerGroup: 2,
          spaceBetween: 1,
        },
        768: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
        480: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
      },
    });

    const showOnEntry = (entries, observer) => {
      entries.forEach(entry => {
        if(entry.isIntersecting) {
          if(entry.target.classList.contains('left-image')) {
            const wrapper = entry.target.closest('.persia__image-wrapper');
            if(wrapper) {
              wrapper.classList.add('persia-animation');
            }
          }
          if(entry.target.classList.contains('oriental__pillow')) {
            entry.target.classList.add('pillow-animation');
          }
        }
      });
    };

    onMounted(() => {
      let options = {threshold: [0.19]};
      let observer = new IntersectionObserver(showOnEntry, options);

      const observeElements = () => {
        let elements = document.querySelectorAll('.left-image, .oriental__pillow');
        if(elements.length === 0) {
          console.error('No elements found for');
        } else {
          elements.forEach(elm => {
            observer.observe(elm);
          });
        }
      };

      setTimeout(observeElements, 100);
    });

    return {
      isMe,
      artLinks,
      artCarouselSettings,
      fabricCarouselSettings,
      gridCarouselSettings
    };
  },
});

</script>
<style scoped lang="scss">
#esthira {
  background-color: var(--togas-black-color);
  color: var(--togas-white-color);

  .mobile-only {
    display: block;

    @include for-m {
      display: none;
    }
  }

  .desk-only {
    display: none;

    @include for-m {
      display: block;
    }
  }

  .hero {
    position: relative;

    &__video {
      height: 620px;
      object-fit: cover;

      @include for-s {
        height: 1200px;
      }

      @include for-m {
        height: 620px;
      }

      @include for-l {
        height: 760px;
      }

      @include for-xl {
        height: 800px;
      }
    }

    &__title-wrap {
      position: absolute;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &__title, &__subtitle {
      text-align: center;
      text-transform: capitalize;
      letter-spacing: 0.03em;
    }

    &__title {
      font: var(--font-weight--medium) var(--font-size--xl) / var(--font-size--xl) var(--font-family--secondary);

      @include for-s {
        font: var(--font-weight--medium) var(--font-size--xxxl) / var(--font-size--xxxl) var(--font-family--secondary);
      }

      @include for-m {
        font: var(--font-weight--medium) var(--font-size--xxxl) / var(--font-size--xxxl) var(--font-family--secondary);
      }

      @include for-l {
        font: var(--font-weight--normal) 62px / 68.2px var(--font-family--secondary);
      }
    }

    &__subtitle {
      font: var(--font-weight--light) var(--font-size--lg) / var(--line-height--sm) var(--font-family--primary);

      @include for-s {
        font: var(--font-weight--light) 22px / 26.4px var(--font-family--primary);
      }

      @include for-l {
        font: var(--font-weight--light) 30px / 33px var(--font-family--secondary);
      }
    }

    &__link-wrapper {
      position: absolute;
      left: 50%;
      bottom: 30px;
      transform: translate(-50%, -50%);
      width: 100%;
      padding: 0 var(--spacer-sm);

      @include for-desktop {
        width: var(--togas-content-max-width);
        padding: 0 var(--spacer-xl);
      }
    }

    &__link {
      @include for-mobile {
        display: block;
        width: 60px;
        margin: 0 auto;
      }
    }

    &__logo {
      width: 60px;
      height: 50px;

      @include for-m {
        position: static;
        float: right;
      }

      @include for-l {
        width: 84px;
        height: 70px;
      }
    }
  }

  .persia {
    padding: var(--spacer-xl) var(--spacer-sm);

    @include for-s {
      padding: 65px 60px;
    }

    @include for-m {
      max-width: 920px;
      position: relative;
      margin: 0 auto;
      padding: 80px 0 65px;
    }

    @include for-l {
      max-width: calc(var(--togas-content-max-width) - 80px);
      padding: 130px 0 65px;
    }

    @include for-xl {
      max-width: calc(var(--togas-content-max-width) - 80px);
      padding: 230px 0 65px;
    }

    &__title-wrap {
      margin-bottom: 30px;

      @include for-m {
        text-align: left;
        width: 450px;
      }

      @include for-l {
        width: 540px;
      }

      @include for-xl {
        width: 680px;
      }
    }

    &__title, &__subtitle {
      text-align: center;
      letter-spacing: 0.03em;
    }

    &__title {
      font: var(--font-weight--medium) 24px / 24px var(--font-family--secondary);
      margin-bottom: 16px;

      @include for-s {
        font: var(--font-weight--medium) 32px / 38.4px var(--font-family--secondary);
        margin-bottom: 20px;
      }

      @include for-m {
        font: var(--font-weight--medium) 32px / 38.4px var(--font-family--secondary);
        text-align: left;
      }

      @include for-l {
        font: var(--font-weight--normal) 42px / 42px var(--font-family--secondary);
      }
    }

    &__subtitle {
      display: block;
      width: 270px;
      margin: 0 auto;
      text-align: center;

      font: var(--font-weight--light) 14px / 16.8px var(--font-family--primary);

      @include for-s {
        font: var(--font-weight--light) 18px / 19.4px var(--font-family--primary);
        width: auto;
      }

      @include for-m {
        text-align: left;
      }
    }

    &__image-wrapper {
      position: relative;

      @include for-m {
        position: static;
      }

      &.persia-animation {
        .left-image {
          left: calc((100vw - 238px - 120px) / 2 - 16px);
          opacity: 1;
          margin: 0;

          @include for-s {
            left: calc((100vw - 469px - 222px) / 2 - 60px);
            height: auto;
          }

          @include for-m {
            position: relative;
            top: 0;
            left: 0;
            transform: none;
          }
        }

        .right-image {
          margin-left: calc((100vw - 238px - 120px) / 2 + 100px);
          opacity: 1;

          @include for-s {
            margin-left: calc((100vw - 469px - 222px) / 2 + 160px);
          }

          @include for-m {
            top: unset;
            bottom: 66px;
            right: 0;
            margin-left: 0;
            opacity: 1;
          }

          @include for-l {
            max-height: 639px;
          }

          @include for-l {
            max-height: 790px;
          }

          @include for-xl {
            bottom: 65px;
            max-height: 961px;
          }
        }
      }
    }

    .left-image {
      height: 230px;
      position: absolute;
      top: 55%;
      left: -20%;
      transform: translateY(-61%);
      width: 183px;
      opacity: .5;
      z-index: 2;
      transition: all 1.5s ease-in-out;
      margin-top: 150px;

      @include for-s {
        width: 360px;
        height: 100%;
      }

      @include for-m {
        position: relative;
        top: 0;
        left: -10%;
        transform: none;
        width: 452px;
        margin-top: 150px;
      }

      @include for-l {
        width: 560px;
      }

      @include for-xl {
        width: 680px;
      }
    }

    .right-image {
      height: 320px;
      width: 238px;
      margin-left: 70vw;
      z-index: 2;
      transition: all 1.5s ease-in-out;
      opacity: .5;

      @include for-s {
        width: 469px;
        height: auto;
      }

      @include for-m {
        position: absolute;
        top: 230px;
        right: 5%;
        margin-top: 0;
        width: 452px;
      }

      @include for-l {
        width: 560px;
      }

      @include for-xl {
        width: 680px;
      }
    }
  }

  .oriental {
    padding-bottom: 40px;

    @include for-m {
      padding-bottom: 65px;
    }

    @include for-l {
      padding-bottom: 80px;
    }

    &__title-wrap--mob {
      display: block;
      margin: 0 auto;

      @include for-s {
        max-width: 610px;
      }

      @include for-m {
        display: none;
      }
    }

    &__title--mob {
      margin-bottom: 12px;
      padding: 0 16px;
      font-family: "PFRegalTextPro", serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: .72px;
      text-align: center;
      color: #fff;
    }

    &__subtitle--mob {
      padding: 0 16px 8px;
      margin-bottom: 0;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      text-align: center;
      letter-spacing: .7px;
      color: #fff;
    }

    .bg-container {
      position: relative;
    }

    .container {

      @include for-m {
        position: absolute;
        top: 83px;
        left: 0;
        right: 0;
        display: flex;
        max-width: var(--togas-content-max-width);
        margin: 0 auto;
        padding: 0 40px;
        align-items: center;
      }

      @include for-l {
        top: 133px;
      }

      @include for-xl {
        top: 105px;
      }
    }

    &__title-wrap {
      display: none;

      @include for-m {
        display: block;
        width: 451px;
        margin-left: 16px;
      }

      @include for-xl {
        width: 640px;
      }
    }

    &__title, &__subtitle {
      text-align: center;
      letter-spacing: 0.03em;
    }

    &__title {
      font: var(--font-weight--medium) 24px / 24px var(--font-family--secondary);
      margin-bottom: 16px;

      @include for-s {
        font: var(--font-weight--medium) 32px / 38.4px var(--font-family--secondary);
        margin-bottom: 20px;
      }

      @include for-m {
        font: var(--font-weight--medium) 32px / 38.4px var(--font-family--secondary);
        text-align: left;
      }

      @include for-l {
        font: var(--font-weight--normal) 42px / 42px var(--font-family--secondary);
      }
    }

    &__subtitle {
      font: var(--font-weight--light) 14px / 16.8px var(--font-family--primary);

      @include for-s {
        font: var(--font-weight--light) 18px / 19.4px var(--font-family--primary);
      }

      @include for-m {
        text-align: left;
      }
    }

    &__pillow {
      position: absolute;
      bottom: -55%;
      padding: 0 30px;
      margin: 0 auto;
      opacity: 1;
      transition: all 2.5s ease-in-out;

      @include for-s {
        left: 50%;
        transform: translateX(-50%);
        width: 530px;
        padding: 0;
      }

      @include for-m {
        position: relative;
        left: -10%;
      }

      @include for-xl {
        width: 680px;
      }

      &.pillow-animation {
        transform: translateY(-57%);

        @include for-s {
          transform: translateY(-79%) translateX(-50%);
        }

        @include for-m {
          left: 0;
          transform: none;
        }
      }
    }
  }

  .art {
    margin: 0 0 30px 0;

    @media (min-width: 700px) and (max-width: 1000px) {
      margin: 0 0 65px 0;
    }

    @include for-m {
      max-width: 100%;
      display: flex;
      margin: 0 0 65px calc((100% - 920px) / 2);
      gap: 16px;
      flex-direction: row;
      align-items: center;
    }

    @include for-l {
      margin: 0 0 80px calc((100% - 1160px) / 2);
      gap: 30px;
    }

    @include for-xl {
      margin: 0 0 80px calc((100% - 1400px) / 2);
      gap: 40px;
    }

    &__title-wrap {
      padding: 0 var(--spacer-sm) var(--spacer-xl);
      text-align: center;

      @include for-m {
        min-width: 360px;
        text-align: left;
        padding: 0;
      }

      @include for-xl {
        min-width: 440px;
      }
    }

    &__title, &__subtitle {
      text-align: center;
      letter-spacing: 0.03em;
    }

    &__title {
      font: var(--font-weight--medium) 24px / 24px var(--font-family--secondary);
      margin-bottom: 16px;

      @include for-s {
        font: var(--font-weight--medium) 32px / 38.4px var(--font-family--secondary);
        margin-bottom: 20px;
      }

      @include for-m {
        font: var(--font-weight--medium) 32px / 38.4px var(--font-family--secondary);
        text-align: left;
      }

      @include for-l {
        font: var(--font-weight--normal) 42px / 42px var(--font-family--secondary);
      }
    }

    &__subtitle {
      font: var(--font-weight--light) 14px / 16.8px var(--font-family--primary);

      @include for-s {
        font: var(--font-weight--light) 18px / 19.4px var(--font-family--primary);
      }

      @include for-m {
        text-align: left;
      }
    }

    &__link {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 213px;
      height: 34px;
      margin: 30px auto 0;
      padding: 10px 0;
      border: 1px solid #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: .7px;
      color: #fff;
      text-align: center;
      background: rgba(255, 255, 255, .1);
      text-decoration: none;

      @include for-m {
        display: flex;
        width: 291px;
        height: 58px;
        margin: 36px 0 0;
        border: 2px solid;
        font-size: 20px;
        font-style: normal;
        letter-spacing: 1.6px;
      }

      &:hover {
        background: rgba(255, 255, 255, .3);
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1.6px;
      }

      &.desk-only {
        display: none;

        @include for-m {
          display: flex;
        }
      }

      &.mobile-only {
        display: flex;

        @include for-m {
          display: none;
        }
      }
    }

    &__br {
      display: none;

      @media (min-width: 700px) and (max-width: 1000px) {
        display: block;
      }
    }

    img {
      aspect-ratio: 1;
    }

    .swiper-container {

      @include for-m {
        padding: 40px;
      }

      &::before {
        content: "";
        position: absolute;
        left: 40px;
        width: 100vw;
        height: 100%;
        z-index: 2;
        background: #0f1319;
        display: block;
        transform: translateX(-100%);
      }
    }

    .swiper-slide {
      @include for-m {
        max-width: 390px;
      }

      @include for-l {
        max-width: 512px;
      }
    }
  }

  .fabric {
    position: relative;

    &__title-wrapper {
      position: absolute;
      top: 26px;
      width: 100%;
      padding: 0 var(--spacer-sm);
      z-index: 2;
      text-align: center;

      @include for-s {
        max-width: 620px;
        left: 50%;
        transform: translateX(-50%);
      }

      @include for-m {
        left: calc((100vw - 920px) / 2);
        right: 0;
        transform: none;
        max-width: 390px;
        padding: 0;
        margin: 0;
        text-align: left;

        &:after {
          content: url("~static/esthira/sensotex-logo.svg");
          display: block;
          transform: scale(1.2);
          width: 76px;
          height: 63px;
          margin: 30px 0 0 94px;
        }
      }

      @include for-l {
        left: calc((100vw - var(--togas-content-max-width)) / 2);
        padding: var(--spacer-xl) var(--spacer-xl) 0;

        &:after {
          transform: scale(1.7);
          width: 106px;
          height: 88px;
          margin: 50px 0 0 180px;
          width: 106px;
          height: 88px;
        }

        top: 100px;
      }

      @include for-xl {
        max-width: 500px;
      }
    }

    &__title {
      font: var(--font-weight--medium) 24px / 24px var(--font-family--secondary);
      margin-bottom: 16px;

      @include for-s {
        font: var(--font-weight--medium) 32px / 38.4px var(--font-family--secondary);
        margin-bottom: 20px;
      }

      @include for-m {
        font: var(--font-weight--medium) 32px / 38.4px var(--font-family--secondary);
        text-align: left;
      }

      @include for-l {
        font: var(--font-weight--normal) 42px / 42px var(--font-family--secondary);
      }
    }

    &__subtitle {
      font: var(--font-weight--light) 14px / 16.8px var(--font-family--primary);

      @include for-s {
        font: var(--font-weight--light) 18px / 19.4px var(--font-family--primary);
      }
    }

    &__title {
      margin-bottom: var(--spacer-m);
    }

    &__subtitle {
      @include for-m {
        display: block;
        width: 390px;
      }

      @include for-xl {
        width: 410px;
      }
    }

    &__logo {
      position: absolute;
      bottom: 42px;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 60px;
      height: 50px;
      z-index: 2;

      @include for-m {
        display: none;
      }
    }

    &__pagination {
      display: flex;
      justify-content: center;
      z-index: 2;
      --swiper-pagination-color: var(--togas-white-color);

      @include for-m {
        position: absolute;
        top: 0;
        left: calc((100vw - 920px) / 2 - 16px);
        height: 100%;
        flex-direction: column;
        gap: var(--spacer-sm);
      }

      @include for-m {
        left: calc((100vw - var(--togas-content-max-width)) / 2 - 16px);
      }
    }
  }

  .grid-slider {
    padding: var(--spacer-xl) var(--spacer-sm) 0;

    @include for-desktop($tablet-min) {
      padding: var(--spacer-xl) 60px 0;
    }

    @include for-m {
      position: relative;
      max-width: 920px;
      max-width: var(--togas-content-max-width);
      margin: 0 auto;
    }

    @include for-desktop() {
      position: relative;
      max-width: var(--togas-content-max-width);
      padding: var(--spacer-xl) var(--spacer-xl);
    }

    @include for-l {
      max-width: var(--togas-content-max-width);
    }

    &__wrapper {
      display: grid;
      grid-template-rows: 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 1px;

      &-image {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: .75;

        &:hover {
          opacity: 1;
        }
      }

      &-image--1, &-image--10 {
        grid-row-start: 1;
        grid-column-start: 1;
        grid-row-end: 2;
        grid-column-end: 2;

      }

      &-image--2, &-image--11 {
        grid-row-start: 2;
        grid-column-start: 1;
        grid-row-end: 3;
        grid-column-end: 2;
      }

      &-image--3, &-image--12 {
        grid-row-start: 1;
        grid-column-start: 2;
        grid-row-end: 3;
        grid-column-end: 4;
      }

      &-image--4, &-image--13 {
        grid-row-start: 3;
        grid-column-start: 1;
        grid-row-end: 5;
        grid-column-end: 3;
      }

      &-image--5, &-image--14 {
        grid-row-start: 3;
        grid-column-start: 3;
        grid-row-end: 5;
      }

      &-image--6, &-image--15 {
        grid-row-start: 1;
        grid-column-start: 1;
        grid-row-end: 3;
        grid-column-end: 2;
      }

      &-image--7, &-image--16 {
        grid-row-start: 3;
        grid-column-start: 1;
        grid-row-end: 4;
        grid-column-end: 2;
      }

      &-image--8, &-image--17 {
        grid-row-start: 4;
        grid-column-start: 1;
        grid-row-end: 5;
        grid-column-end: 2;
      }

      &-image--9, &-image--18 {
        grid-row-start: 1;
        grid-column-start: 2;
        grid-row-end: 5;
        grid-column-end: 4;
      }
    }
  }
}
</style>
<style lang="scss">
#esthira {

  .art {
    .swiper-button-prev, .swiper-button-next {
      top: 45%;
      z-index: 3;
      opacity: .6;
    }

    .swiper-button-prev {
      left: 0%;
    }
  }

  .fabric {
    .swiper-container {

      @include for-m {
        height: 405px
      }

      @include for-l {
        height: 520px
      }

      @include for-xl {
        height: 570px
      }
    }

    .swiper-pagination {

      @include for-m {
        position: absolute;
        flex-direction: column;
        gap: var(--spacer-sm);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 405px;
        width: 100%;
        max-width: calc((100vw - 960px) / 2 + 960px);
        align-items: flex-start;
        margin: 0 auto;
      }

      @include for-l {
        height: 520px;
        max-width: calc((100vw - 1129px) / 2 + 1200px);
      }

      @include for-xl {
        height: 570px;
        max-width: 1550px;
      }
    }

    .swiper-pagination-bullet {

      @include for-m {
        justify-content: center;
      }
    }

    .swiper-pagination-bullet-active {
      .swiper-pagination-bullet-inner {

        @include for-m {
          width: 3px;
        }
      }
    }

    .swiper-pagination-bullet-inner {

      @include for-m {
        height: 34px;
        width: 1px;
      }
    }
  }

  .grid-slider {
    .swiper-button-prev {
      left: 3%;

      @include for-desktop {
        left: 0;
      }

      @include for-xl {
        left: 0;
      }
    }

    .swiper-button-next {
      right: 3%;

      @include for-desktop {
        right: 0;
      }
    }

    .swiper-button-prev, .swiper-button-next {

      @include for-m {
        display: block;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        z-index: 1;
        cursor: pointer;
        background: transparent url("~~/static/icons/swiper/swiper-arrow-white.svg") no-repeat center;
      }
    }

    .swiper-pagination {
      margin-top: 10px;
    }
  }
}
</style>
