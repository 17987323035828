






































































































































import {
  SfButton,
  SfHeading,
} from '~/components';

import SkeletonLoader from '~/components/SkeletonLoader/index.vue';

import {
  computed,
  defineComponent,
  onMounted,
  ref,
  ssrRef,
  useContext,
  useFetch,
  useRoute,
  useRouter,
  watch,
} from '@nuxtjs/composition-api';

import LazyHydrate from 'vue-lazy-hydration';

import {
  CacheTagPrefix,
  useCache
} from '@vue-storefront/cache';

import { usePageStore } from '~/stores/page';


import {
  useCategory,
  useFacet,
  useUiHelpers,
  useUiNotification,
  useUiState,
  useUser,
  useWishlist,
  useCategoryContent
} from '~/composables';

import { useAddToCart } from '~/helpers/cart/addToCart';
import { usePrice } from '~/modules/catalog/pricing/usePrice';
import { useTraverseCategory } from '~/modules/catalog/category/helpers/useTraverseCategory';
import facetGetters from '~/modules/catalog/category/getters/facetGetters';
import { getMetaInfo } from '~/helpers/getMetaInfo';

import type { ProductInterface } from '~/modules/GraphQL/types';
import type { SortingModel } from '~/modules/catalog/category/composables/useFacet/sortingOptions';
import type { Pagination } from '~/composables/types';
import { sendEcommerce } from '~/utilities/helpers';
import {onSSR} from "@vue-storefront/core";

export default defineComponent({
  name: 'CategoryPage',
  components: {
    QuickShop: () => import('~/modules/catalog/category/components/quickshop/QuickShop.vue'),
    CategoryEmptyResults: () => import('~/modules/catalog/category/components/CategoryEmptyResults.vue'),
    CategoryFilters: () => import('~/modules/catalog/category/components/filters/CategoryFilters.vue'),
    CmsContent: () => import('~/modules/catalog/category/components/cms/CmsContent.vue'),
    CategoryNavbar: () => import('~/modules/catalog/category/components/navbar/CategoryNavbar.vue'),
    CategoryBreadcrumbs: () => import('~/modules/catalog/category/components/breadcrumbs/CategoryBreadcrumbs.vue'),
    CollectionContent: () => import('~/modules/catalog/category/components/collections/CollectionContent.vue'),
    CategoryProductGrid: () => import('~/modules/catalog/category/components/views/CategoryProductGrid.vue'),
    CategoryProductList: () => import('~/modules/catalog/category/components/views/CategoryProductList.vue'),
    CategoryPagination: () => import('~/modules/catalog/category/components/pagination/CategoryPagination.vue'),
    ProductSizeSidebar: () => import('~/modules/catalog/product/components/size-table/ProductSizeSidebar.vue'),
    SfButton,
    SfHeading,
    SkeletonLoader,
    LazyHydrate,
  },
  transition: 'fade',
  setup() {
    const { app } = useContext();
    const { routeData } = usePageStore();
    const { getContentData } = useCategoryContent();
    const { loadCategoryMeta } = useCategory();
    const { isAuthenticated } = useUser();
    const { send: sendNotification } = useUiNotification();
    const { addItemToCart } = useAddToCart();
    const uiHelpers = useUiHelpers();
    const router = useRouter();
    const route = useRoute();

    const {
      result,
      search,
      loading
    } = useFacet();

    const {
      toggleFilterSidebar,
      changeToCategoryListView,
      changeToCategoryGridView,
      isCategoryGridView,
      isFilterSidebarOpen,
      isProductSizeSidebarOpen
    } = useUiState();

    const {
      load: loadWishlist,
      addOrRemoveItem
    } = useWishlist();

    const { addTags } = useCache();
    onSSR(() => {
      addTags([{ prefix: CacheTagPrefix.Category, value: routeData.uid }]);
    })

    const cmsContent = ref('');
    const isQuickShop = ref(false);
    const collectionContent = ref(null);
    const isShowCms = ref(false);
    const isCollectionPage = ref(false);
    const isShowProducts = ref(false);
    const isQuickShopOpen = ref(false);
    const products = ssrRef<ProductInterface[]>([]);
    const sortBy = ref<SortingModel>({ selected: '', options: [] });
    const pagination = ref<Pagination>({});
    const productContainerElement = ref<HTMLElement | null>(null);

    const categoryMeta = ref(null);
    const loadedPages = ref([]);
    const startPage = ref();
    const sizeTable = ref('');

    const addItemToWishlist = async (product) => {
      if (isAuthenticated.value) {
        await addOrRemoveItem({ product });
      } else {
        await router.push(app.localeRoute({ name: 'customer-login-register', query: { redirect: route.value.path } }));
        sendNotification({
          id: Symbol('need_to_login'),
          message: app.i18n.tc('Need to login message'),
          type: 'warning',
          icon: 'check',
          persist: false,
        });
      }
    };

    const {
      activeCategory,
      loadCategoryTree,
      categoryAncestors
    } = useTraverseCategory();
    const activeCategoryName = computed(() => activeCategory.value?.name ?? '');

    const categoryUid = routeData.uid;

    let sku = ref(null);
    let regularPrice = ref(null);
    let specialPrice = ref(null);
    const handleQuickShop = ({ currentSku, regularPriceVal, specialPriceVal }) => {
      isQuickShopOpen.value = true;
      sku.value = currentSku;
      regularPrice.value = regularPriceVal;
      specialPrice.value = specialPriceVal;
    };

    const { fetch } = useFetch(async () => {
      if (!activeCategory.value) {
        await loadCategoryTree();
      }

      const [content, categoryMetaData] = await Promise.all([
        getContentData(categoryUid as string),
        loadCategoryMeta({ category_uid: categoryUid }),
        search({ ...uiHelpers.getFacetsFromURL(), category_uid: categoryUid }),
      ]);

      categoryMeta.value = categoryMetaData;
      cmsContent.value = content?.cmsBlock?.content ?? '';
      collectionContent.value = content?.collectionContent && content?.collectionContent['is_active']
        ? content?.collectionContent
        : null;
      isShowCms.value = content.isShowCms;
      isQuickShop.value = content.isQuickShop;
      isShowProducts.value = content.isShowProducts;
      isCollectionPage.value = content.collectionContent && content.collectionContent['is_active'];

      products.value = facetGetters.getProducts(result.value) ?? [];

      sortBy.value = facetGetters.getSortOptions(result.value);
      pagination.value = facetGetters.getPagination(result.value);

      const tags = [{ prefix: CacheTagPrefix.View, value: routeData.uid }];
      const productTags = products.value.map((product) => ({
        prefix: CacheTagPrefix.Product,
        value: product?.uid,
      }));

      addTags([...tags, ...productTags]);
    });

    const isPriceLoaded = ref(false);
    const nextPage = ref(0);

    onMounted(async () => {
      loadWishlist();
      loadedPages.value.push(uiHelpers.getFacetsFromURL().page);
      startPage.value = uiHelpers.getFacetsFromURL().page;
      nextPage.value = uiHelpers.getFacetsFromURL().page + 1;

      const { getPricesBySku } = usePrice();
      if (products.value.length > 0) {
        const skus = products.value.map((item) => item?.sku);
        const priceData = await getPricesBySku(skus, pagination.value.itemsPerPage);
        products.value = products.value.map((product) => {
          const priceRange = priceData.items.find((item) => item?.sku === product?.sku)?.price_range;

          if (priceRange) {
            return {
              ...product,
              price_range: priceRange,
            };
          }

          return { ...product };
        });
      }

      isPriceLoaded.value = true;
      sendEcommerceCategory();
    });

    const goToPage = (page: number) => {
      uiHelpers.changePage(page, false);
      fetch();
      productContainerElement.value.scrollIntoView();
      loadedPages.value = [];
      loadedPages.value.push(uiHelpers.getFacetsFromURL().page);
      startPage.value = uiHelpers.getFacetsFromURL().page;
      nextPage.value = uiHelpers.getFacetsFromURL().page + 1;
      sendEcommerceCategory();
    };

    const doChangeItemsPerPage = (itemsPerPage: number) => {
      uiHelpers.changeItemsPerPage(itemsPerPage, false);
      goToPage(0);
    };

    const onReloadProducts = () => {
      goToPage(0);
      productContainerElement.value.scrollIntoView();
      sendEcommerceCategory();
    };

    const loadingProducts = ref(false);
    const loadMoreProducts = async () => {
      loadingProducts.value = true;
      await search({
        ...uiHelpers.getFacetsFromURL(),
        page: nextPage.value,
        category_uid: categoryUid,
      });
      products.value = products.value.concat(facetGetters.getProducts(result.value));
      sendEcommerceCategory(facetGetters.getProducts(result.value));
      loadedPages.value.push(nextPage.value);
      loadingProducts.value = false;
      nextPage.value += 1;
    };

    const scrollPage = ref();
    watch(scrollPage, () => {
      pagination.value = { ...pagination.value, currentPage: startPage.value + scrollPage.value - 1 };
      const url = new URL(location.href);
      url.searchParams.set('page', (startPage.value + scrollPage.value - 1).toString());
      window.history.pushState(null, null, url.toString());
    });

    const visibilityChanged = ({ isVisible, entry }) => {
      if (isVisible) {
        scrollPage.value = Math.round(entry.target.dataset.index / 36);
        scrollPage.value = Math.round(entry.target.dataset.index / 36) < 1
          ? 1
          : Math.round(entry.target.dataset.index / 36);
      }
    };

    // Ecommerce and Pixels
    const ecommerceCategory = categoryAncestors.value;

    const sendEcommerceCategory = (items = products.value) => {
      const productsData = [];
      items.forEach((product) => {
        productsData.push(
          Object.assign(
            product,
            { categories: categoryAncestors.value },
            { item_list_name: activeCategoryName.value },
            { item_list_id: routeData.uid }
          )
        );
      });

      sendEcommerce('view_item', 'category', productsData);
    };

    const sendEcommerceQuickShop = (product) => {
      sendEcommerce(
        'view_item',
        'product',
        Object.assign(product, { categories: ecommerceCategory }),
        {
          'goods_id': product.sku,
          'goods_price': product.price_range.minimum_price.final_price.value
        }
      );
    };

    return {
      isPriceLoaded,
      ...uiHelpers,
      toggleFilterSidebar,
      isCategoryGridView,
      changeToCategoryListView,
      changeToCategoryGridView,
      isFilterSidebarOpen,
      addItemToCart,
      addItemToWishlist,
      pagination,
      products,
      sortBy,
      isShowCms,
      isShowProducts,
      isCollectionPage,
      collectionContent,
      isQuickShopOpen,
      cmsContent,
      activeCategoryName,
      routeData,
      doChangeItemsPerPage,
      productContainerElement,
      categoryMeta,
      onReloadProducts,
      goToPage,
      loading,
      loadMoreProducts,
      nextPage,
      loadingProducts,
      visibilityChanged,
      handleQuickShop,
      sku,
      specialPrice,
      regularPrice,
      isProductSizeSidebarOpen,
      sizeTable,
      isQuickShop,
      sendEcommerceQuickShop
    };
  },
  head() {
    return getMetaInfo(this.categoryMeta);
  }
});
