<template>
  <Carousel
    v-if="!loading"
    :settings="carouselSettings"
    :container-class="'main-carousel'"
    :carousel-items-qty="+banners.length"
    inside-bullets
    white-bullets
  >
    <div
      v-for="(banner, i) in banners"
      :key="banner.title"
      class="main-carousel__slide swiper-slide"
    >
      <div v-if="banner.video_desktop_url || banner.video_mobile_url">
        <video
          class="main-carousel__item-video desktop-only"
          width="100%"
          height="100%"
          muted
          autoplay
          playsinline
          loop
          :poster="banner.video_desktop_preview_url"
        >
          <source
            type="video/mp4; codecs=&quot;avc1.42E01E, mp4a.40.2&quot;"
            :src="banner.video_desktop_url"
          />
          <track src="assets/sub.vtt" kind="captions" srclang="en">
        </video>
        <video
          class="main-carousel__item-video mobile-only"
          width="100%"
          height="100%"
          muted
          autoplay
          playsinline
          loop
          :poster="banner.video_mobile_preview_url"
        >
          <source
            type="video/mp4; codecs=&quot;avc1.42E01E, mp4a.40.2&quot;"
            :src="banner.video_mobile_url"
          />
          <track src="assets/sub.vtt" kind="captions" srclang="en">
        </video>
      </div>
      <picture
        v-else
        class="main-carousel__item-image-container"
      >
        <source
          :srcset="banner.image_desktop_url"
          media="screen and (min-width: 1024px)"
        />
        <img
          class="main-carousel__item-image"
          :src="banner.image_mobile_url"
          :alt="banner.title"
          :loading="i > 0 ? 'lazy' : 'eager'"
        />
      </picture>
      <div
        class="main-carousel__content"
        :class="banner.css_classes"
      >
        <p class="main-carousel__content--subtitle">{{ banner.subtitle }}</p>
        <h1 class="main-carousel__content--title" v-html="banner.title" />
        <a
          v-if="banner.is_url_external"
          class="main-carousel__content--link swiper-no-swiping sf-link"
          :href="banner.url"
          target="_blank"
          :style="{ color: banner.css_classes === 'text-white' ? 'white' : '' }"
        >
          {{ banner.callout }}
        </a>
        <SfLink
          v-else
          class="main-carousel__content--link swiper-no-swiping"
          :link="decodeURIComponent(localePath(banner.url))"
          :style="{ color: banner.css_classes === 'text-white' ? 'white' : '' }"
        >
          {{ banner.callout }}
        </SfLink>
      </div>
    </div>
  </Carousel>
  <SkeletonLoader
    v-else
    skeleton-class="main-carousel__skeleton"
    :dark-shimmer="true"
  />
</template>
<script>
import {
  defineComponent,
  ref,
  useFetch,
  useContext,
} from '@nuxtjs/composition-api';

import {
  SfLink,
  SkeletonLoader,
} from '~/components';

import { PromoBannerGroupType } from '~/constants';

export default defineComponent({
  name: 'MainSlider',
  components: {
    SfLink,
    SkeletonLoader,
    Carousel: () => import('~/components/togas/Carousel.vue'),
  },
  setup() {
    const { app } = useContext();

    const banners = ref([]);
    const loading = ref(false);
    const carouselSettings = ref({
      loop: true,
      autoplay: {
        delay: 6000,
      },
      speed: 1500,
      breakpoints: {
        1024: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
        768: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
        480: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
      },
    });

    useFetch(async () => {
      try {
        loading.value = true;
        const { data, errors } = await app.$vsf.$magento.api.getPromoBannerGroups(PromoBannerGroupType.MAIN_SLIDER);

        if (errors) console.warn(errors[0].message);

        banners.value = data?.promoBannerGroups?.items[0]?.items;
      }
      catch (error) {
        console.error('Error in MainSlider.vue: ', error);
      }
      finally {
        loading.value = false;
      }
    });

    return {
      banners,
      carouselSettings,
      loading,
    };
  }
});
</script>

<style lang="scss" scoped>
.main-carousel {
  background: var(--togas-black-color);
  top: calc(-1 * var(--header-height));
  margin-bottom: calc(-1 * var(--header-height));

@include for-desktop($desktop-l-min) {
    top: calc(-1 * var(--header-height));
    margin-bottom: calc(-1 * var(--header-height));
  }

  &__item {
    position: relative;
  }

  &__item-image,
  &__item-video {
    display: block;
    width: 100%;
    height: 100svh;
    object-fit: cover;

    @include for-desktop {
      aspect-ratio: 2 / 1;
      height: 100vh;
    }
  }

  &__item-video {

    @include for-desktop {
      object-fit: cover;
    }
  }

  &__content {
    position: absolute;
    top: 76px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    max-width: var(--togas-content-max-width);
    padding: 0 var(--spacer-sm);
    white-space: normal;
    z-index: 1;

    @include for-desktop {
      top: calc(50% - 85px);
      align-items: flex-start;
      padding: 0 40px;
    }

    &--subtitle {
      font: var(--font-weight--light) 12px /13px var(--font-family--primary);
      letter-spacing: 0.02em;
      text-align: center;
      text-transform: uppercase;
      margin: 0 0 var(--spacer-xs);

      @include for-desktop {
        font: var(--font-weight--light) 12px /18px var(--font-family--primary);
      }

      @include for-xl {
        font: var(--font-weight--light) 16 px /18px var(--font-family--primary);
      }
    }

    &--title {
      font: var(--font-weight--light) 36px / 36px var(--font-family--secondary);

      @include for-desktop {
        font: var(--font-weight--light) 40px / 40px var(--font-family--secondary);
        margin: 0 0 var(--spacer-m);
        text-align: left;
      }

      @include for-xl {
        font: var(--font-weight--light) 44px / 44px var(--font-family--secondary);
      }
    }

    &--link {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font: var(--font-weight--medium) var(--font-size--xs) / var(--line-height--xs) var(--font-family--primary);
      width: auto;
      height: 38px;
      letter-spacing: 0.01em;
      padding: var(--spacer-base);

      @include for-desktop {
        font: var(--font-weight--medium) 17px / var(--line-height--xs) var(--font-family--primary);
        padding: var(--spacer-m) var(--spacer-m) var(--spacer-m) 0;
      }
    }
  }

  &__skeleton {
    width: 100%;
    height: 80vh;
    margin-bottom: 20px;

    @include for-desktop {
      height: 70vh;
    }

    .skeleton {
      height: 100%;
    }
  }
}
</style>
