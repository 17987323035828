<template>
  <div
    class="size-modal-btn"
    @click="toggleProductSizeSidebar"
  >
    <button>
      <size-icon />
      <span>{{ $t('Show size-table') }}</span>
    </button>
  </div>
</template>

<script type="ts">
import { defineComponent } from '@nuxtjs/composition-api';
import { SizeIcon } from '~/components';
import { useUiState } from '~/composables';

export default defineComponent({
  name: 'SizeTableButton',
  components: { SizeIcon },
  setup() {
    const {toggleProductSizeSidebar} = useUiState();

    return {
      toggleProductSizeSidebar,
    };
  },
});
</script>

<style lang="scss" scoped>
.size-modal-btn {

  button {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    font-size: var(--font-size--sm);
    line-height: var(--line-height--xs);
    color: var(--togas-grey-color);
  }

  span {
    border-bottom: 1px solid var(--togas-grey-color);
  }
}
</style>
