export default `
query ProductRecommendationQuizzes($quizIds: [String!]) {
  productRecommendationQuizzes(
    filter: {
      is_enabled: { eq: "1" },
      identifier: { in: $quizIds }
    }
  ){
    items {
      title
      subtitle
      thumbnail_image_desktop_url
      thumbnail_image_mobile_url
      url_path
      uid
    }
  }
}
`
