































































































































































import {
  SfIcon,
  SfButton,
  SfProductCardHorizontal,
  SfLink,
  SfHeading,
  SvgImage,
} from '~/components';

import {
  defineComponent,
  ref,
  computed,
  watch,
  useFetch,
  useContext,
  onMounted,
  onBeforeUnmount,
} from '@nuxtjs/composition-api';

import {
  useImage,
  useProduct,
} from '~/composables';

import {
  getOptimizedProductThumbnailImage,
  getName,
  getPrice,
} from '~/modules/catalog/product/getters/productGetters';

import type { ProductShowcaseGroupItem } from '~/modules/GraphQL/types';

import Vue from 'vue';
import VueTippy from 'vue-tippy/dist/vue-tippy.esm';

Vue.use(VueTippy);
Vue.component('tippy');

export default defineComponent({
  name: 'PresentationProduct',
  components: {
    SfHeading,
    SfLink,
    SfIcon,
    SfButton,
    SfProductCardHorizontal,
    SvgImage,
    Carousel: () => import('~/components/togas/Carousel.vue'),
  },
  setup() {
    const { app } = useContext();
    const { getProductPath } = useProduct();
    const { getMagentoImage } = useImage();

    const loading = ref<Boolean>(false);
    const isLoop = ref(null);
    const carouselSettings =ref( {
      loop: isLoop.value,
      breakpoints: {
        1024: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
        768: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
        480: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
      },
    });
    const productListRefs = ref<Array<HTMLElement | null>>([]);
    const isShowProductsList = ref(false);

    const desktopProducts = ref<ProductShowcaseGroupItem[]>();
    const mobileProducts = ref<ProductShowcaseGroupItem[]>();

    const handleProductListClick = (event) => {
      const target = event.target;
      for (const ref of productListRefs.value) {
        if (ref && ref.contains(target)) {
          isShowProductsList.value = true;
          return;
        }
      }
      isShowProductsList.value = false;
    };
    const handleDocumentClick = (event) => {
      const target = event.target;
      const isViewButtonClicked = event.target.closest('.presentation--view-btn');

      if (isViewButtonClicked) {
        return;
      }

      for (const ref of productListRefs.value) {
        if (ref && ref.contains(target)) {
          return;
        }
      }
      isShowProductsList.value = false;
    };

    const tippySettings = {
      trigger: 'click',
      distance: '-15',
      interactive: true
    };
    const renderTippyContent = (product) => {
      const link = `${app.context.base.slice(0, -1)}${app.localePath(getProductPath(product))}`;
      const regularPrice = app.context.$fc(getPrice(product).regular);
      const specialPrice = app.context.$fc(getPrice(product).special);

      if (
        specialPrice === regularPrice ||
        specialPrice.includes('€0.00') ||
        specialPrice.includes('$0.00') ||
        specialPrice.includes('AED 0.00') ||
        specialPrice.includes('AED 0.00') ||
        specialPrice.includes('SAR 0.00') ||
        specialPrice.includes('SAR 0.00')
      ) {
        return `
          <a class="sf-product-card-horizontal presentation__tippy-product" href="${link}">
            <div class="sf-product-card-horizontal__image-wrapper presentation__tippy-product__image-wrapper ">
              <img src="${product.product_thumbnail}" alt="">
            </div>
            <div>
              <div class="presentation__tippy-product__name">${product.name}</div>
              <div class="sf-product-card__price sf-price">
                <span class="sf-price__regular">${regularPrice}</span>
              </div>
            </div>
          </a>
      `;
      }

      if (specialPrice && specialPrice !== regularPrice) {
        return `
          <a class="sf-product-card-horizontal presentation__tippy-product" href="${link}">
            <div class="sf-product-card-horizontal__image-wrapper presentation__tippy-product__image-wrapper">
                <img src="${product.product_thumbnail}" alt="">
            </div>
            <div>
              <div>${product.name}</div>
              <div class="sf-product-card__price sf-price">
                <span class="sf-price__regular display-none"> ${regularPrice}</span>
                <ins class="sf-price__special">${specialPrice}</ins>
                <del class="sf-price__old"> ${regularPrice}</del>
              </div>
            </div>
          </a>
      `;
      }
    };

    const queryVariables = {
      identifiers: ['homepage_desktop', 'homepage_mobile']
    };

    const isReady = computed(() => Boolean(!loading.value && desktopProducts.value.length && isLoop.value !== null));

    useFetch(async () => {
      try {
        loading.value = true;

        const { data, errors } = await app.$vsf.$magento.api.getProductPresentation(queryVariables);

        if (errors) console.warn(errors[0].message);

        desktopProducts.value = data?.productShowcaseGroups?.items?.find(group => group.identifier === 'homepage_desktop')?.items?.items || [];
        mobileProducts.value = data?.productShowcaseGroups?.items?.find(group => group.identifier === 'homepage_mobile')?.items?.items || [];
        isLoop.value = desktopProducts.value.length > 1
      } catch (error) {
        console.error('Error in PresentationProduct.vue: ', error);
      } finally {
        loading.value = false;
      }
    });

    watch(isLoop, (newVal) => {
      carouselSettings.value.loop = newVal;
    });

    onMounted(() => {
      document.addEventListener('click', handleDocumentClick);
    });

    onBeforeUnmount(() => {
      document.removeEventListener('click', handleDocumentClick);
    });

    return {
      getOptimizedProductThumbnailImage,
      getName,
      getPrice,
      getMagentoImage,
      getProductPath,
      desktopProducts,
      mobileProducts,
      productListRefs,
      isShowProductsList,
      handleProductListClick,
      renderTippyContent,
      tippySettings,
      carouselSettings,
      isReady
    };
  },
});
