






























































































































































































































































import {defineComponent, nextTick, onBeforeUnmount, onMounted, ref, useFetch, watch} from '@nuxtjs/composition-api';
import {
  SfButton,
  SfHeading,
  SfIcon,
  SfLink,
  SfImage
} from '~/components';
import {useFacet, useProduct, useUiHelpers} from '~/composables';
import { useCategoryStore } from "~/modules/catalog/category/stores/category";
import facetGetters from "~/modules/catalog/category/getters/facetGetters";
import { CategoryTree } from '~/modules/GraphQL/types'

import Swiper, { Navigation } from 'swiper';
Swiper.use([Navigation]);

export default defineComponent({
  name: 'HandMade',
  components: {
    SfButton,
    SfHeading,
    SfIcon,
    SfLink,
    SfImage,
  },
  head() {
    return {
      title: 'Handmade'
    };
  },
  setup(_, { refs }) {
    const { result, search } = useFacet();
    const { getCatLink } = useUiHelpers();
    const { getProductPath } = useProduct();

    const loading = ref<Boolean>(false);
    const isMounted = ref<Boolean>(false);
    const productsTop = ref([]);
    const productsMiddle = ref([]);
    const productsBottom = ref([]);

    onMounted(() => isMounted.value = true);

    watch([isMounted, loading], () => {
      if (isMounted.value && !loading.value) {
        nextTick(() => {
          initProductsTopSlider();
          initProductsMiddleSlider();
          initProductsBottomSlider();
          productsMiddleSlider.value.slideTo(productsMiddle.value.length);
        });
      }
    });

    useFetch(async () => {
      try {
        loading.value = true;

        const categories = await useCategoryStore();
        if (categories.categories === null) await categories.load();
        const handMadeCategory = categories.categories.children
          .filter(item => item.name ==='Handmade') as unknown as CategoryTree;

        const handMadeTopUIDs = handMadeCategory[0].children
          .reduce((acc, cur) => cur.name === 'Top' ? acc + cur.uid : acc, '');
        await search({ category_uid: handMadeTopUIDs });
        productsTop.value = facetGetters.getProducts(result.value) ?? [];

        const handMadeMiddleUIDs = handMadeCategory[0].children
          .reduce((acc, cur) => cur.name === 'Middle' ? acc + cur.uid : acc, '');
        await search({ category_uid: handMadeMiddleUIDs });
        productsMiddle.value = facetGetters.getProducts(result.value) ?? [];

        const handMadeBottomUIDs = handMadeCategory[0].children
          .reduce((acc, cur) => cur.name === 'Bottom' ? acc + cur.uid : acc, '');
        await search({ category_uid: handMadeBottomUIDs });
        productsBottom.value = facetGetters.getProducts(result.value) ?? [];

      } catch (error) {
        console.error('Error in HandMade.vue: ', error);
      } finally {
        loading.value = false;
      }
    });

    const productsTopSlider = ref(null);
    const productsMiddleSlider = ref(null);
    const productsBottomSlider = ref(null);

    const initProductsTopSlider = () => {
      productsTopSlider.value = new Swiper(refs.productsTopEl as HTMLElement, {
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          dynamicBullets: true,
          dynamicMainBullets: 5,
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '"><span class="swiper-pagination-bullet-inner"> </span></span>';
          }
        },
        observer: true,
        observeParents: true,
        spaceBetween: 24,
        slidesPerGroup: 1,
        breakpoints: {
          1024: {
            slidesPerView: 2.5,
            spaceBetween: 40,
          },
          0: {
            slidesPerView: 2.2,
          },
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });
    };

    const initProductsMiddleSlider = () => {
      productsMiddleSlider.value = new Swiper(refs.productsMiddleEl as HTMLElement, {
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          dynamicBullets: true,
          dynamicMainBullets: 5,
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '"><span class="swiper-pagination-bullet-inner"> </span></span>';
          }
        },
        observer: true,
        observeParents: true,
        spaceBetween: 24,
        slidesPerGroup: 1,
        breakpoints: {
          1024: {
            slidesPerView: 2.5,
            spaceBetween: 40,
          },
          0: {
            slidesPerView: 2.2,
          },
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });
    };

    const initProductsBottomSlider = () => {
      productsBottomSlider.value = new Swiper(refs.productsBottomEl as HTMLElement, {
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          dynamicBullets: true,
          dynamicMainBullets: 5,
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '"><span class="swiper-pagination-bullet-inner"> </span></span>';
          }
        },
        observer: true,
        observeParents: true,
        spaceBetween: 24,
        slidesPerGroup: 1,
        breakpoints: {
          1024: {
            slidesPerView: 2.5,
            slidesPerGroup: 2.5,
            spaceBetween: 40,
          },
          0: {
            slidesPerView: 2.2,
            slidesPerGroup: 2.2,
          },
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });
    };

    onBeforeUnmount(() => {
      if (productsTopSlider.value && productsTopSlider.value.length) productsTopSlider.value.destroy();
      if (productsMiddleSlider.value && productsMiddleSlider.value.length) productsMiddleSlider.value.destroy();
      if (productsBottomSlider.value && productsBottomSlider.value.length) productsBottomSlider.value.destroy();
    });

    return {
      loading,
      getProductPath,
      getCatLink,
      productsTop,
      productsMiddle,
      productsBottom,
    };
  },
});
