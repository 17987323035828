<template>
  <div class="sleep-system">
    <div class="sleep-system__block1">
      <picture>
        <source
          media="screen and (min-width: 1024px)"
          srcset="~/static/sleep-system/1-d.webp"
        />
        <img
          class="img-block"
          src="~/static/sleep-system/1-m.webp"
          alt="Sleep System"
        >
      </picture>
      <div class="content --center --absolute --flex">
        <SfIcon
          class="sleep-system__logo"
          icon="logo_sleep_system"
        />
        <h1 class="title --white">A unified system of&nbsp;innovative bedding products</h1>
        <div class="links">
          <SfLink
            class="--white"
            :link="decodeURIComponent(localePath('/bed-linen.html'))"
          >Pillows</SfLink>
          <SfLink
            class="--white"
            :link="decodeURIComponent(localePath('/pillows.html'))"
          >Duvets</SfLink>
          <SfLink
            class="--white"
            :link="decodeURIComponent(localePath('/bed-linen/mattress-pads.html'))"
          >Mattress pads</SfLink>
        </div>
      </div>
    </div>
    <div class="sleep-system__block2">
      <picture>
        <source
          media="screen and (min-width: 1024px)"
          srcset="~/static/sleep-system/2-d.webp"
        />
        <img
          class="img-block"
          src="~/static/sleep-system/2-m.webp"
          alt="Sleep System"
        >
      </picture>
      <div class="content --absolute grid">
        <div class="col-m-7 desktop-only"></div>
        <div class="col-m-4 --flex --v-center">
          <h2 class="title --white">The synergy of&nbsp;movements</h2>
          <p class="text --underlined --white">We are constantly on the move. When we are awake, our movements are conscious, but we continue to move even when we sleep. During the night, we change body positions dozens of times by following the orders of our internal biorhythms and interacting with the surfaces we sleep on.</p>
          <p class="text --white">Togas introduces the Togas Innovations Sleep System. It is a unified collection of bedding products, anatomic mattresses, and exclusive designer beds.</p>
        </div>
      </div>
    </div>
    <div class="sleep-system__block3">
      <picture>
        <source
          media="screen and (min-width: 1024px)"
          srcset="~/static/sleep-system/3-d.webp"
        />
        <img
          class="img-block"
          src="~/static/sleep-system/3-m.webp"
          alt="Sleep System"
        >
      </picture>
      <div class="content --absolute grid">
        <div class="col-m-5 --flex --v-bottom">
          <h2 class="title">For everybody</h2>
          <p class="text --underlined">We all sleep differently, but the Togas Innovations Sleep System has a solution for everyone, regardless of height, weight, individual features of thermoregulation, or preferred sleeping position.</p>
          <p class="text">Not only do all products have a different combination of materials, designs, and treatments, they also have different functional characteristics which are responsible for various practical tasks in order to achieve perfect sleep.</p>
        </div>
      </div>
    </div>
    <div class="sleep-system__block6">
      <div class="content">
        <h2 class="title">The Togas Innovations Sleep System</h2>
        <hr class="border" />
      </div>
      <div class="scheme mobile-only">
        <svg viewBox="0 96 336 454" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M312 165L312 212.5" :class="{ active: activeScheme === 'a' || activeScheme === 'b' }" />
          <rect x="2" y="98" width="262" height="36" class="selector" :class="{ active: activeScheme === 'a'}"/>
          <text @click="changeScheme('a')" x="126" y="122" class="selector":class="{ active: activeScheme === 'a'}">Material and filler</text>
          <path d="M264 118L312 118" :class="{ active: activeScheme === 'a'}"/>
          <path d="M312 118L312 165.5" :class="{ active: activeScheme === 'a'}"/>
          <rect x="2" y="146" width="262" height="36" class="selector" :class="{ active: activeScheme === 'b'}"/>
          <text @click="changeScheme('b')" x="126" y="170" class="selector" :class="{ active: activeScheme === 'b'}">Contruction</text>
          <path d="M264 165L312 165" :class="{ active: activeScheme === 'b'}"/>
          <rect x="2" y="194" width="262" height="36" class="selector" :class="{ active: activeScheme === 'c'}"/>
          <text @click="changeScheme('c')" x="126" y="218" class="selector" :class="{ active: activeScheme === 'c' }">Processing and saturation</text>
          <path d="M264 212L312 212" :class="{ active: activeScheme === 'c'}" />
          <path d="M312 212L312 240" class="active"/>
          <path d="M314.467 270.243L326.275 289.135H303.713L301.294 293H333V292.044L319.509 270.382L332.158 251.964V251H293V254.873H325.074L314.467 270.243Z" fill="#0F1319"/>
          <path d="M301.54 293.662L301.744 293.352L301.939 293.041L302.27 292.543L303.799 290.19L303.85 290.1L304.054 289.79L310.262 280.239L299.23 264.872H319.588L319.749 264.652L321.635 262.021L321.855 261.711L322.076 261.4L322.365 261H291.875V261.964L305.022 280.378L291.051 301.962L291 302.036V303H326V299.128H297.99L301.285 294.054L301.54 293.662Z" fill="#907C4B"/>
          <path d="M312 313L312 341" class="active"/>
          <rect x="2" y="324" width="262" height="36" class="variant" :class="{ active: activeScheme === 'b'}"/>
          <text x="126" y="348" class="variant" :class="{ active: activeScheme === 'b'}">Anatomic properties</text>
          <path d="M264 341L312 341" :class="{ active: activeScheme === 'b'}" />
          <rect x="2" y="372" width="262" height="36" class="variant" :class="{ active: activeScheme === 'a' || activeScheme === 'c'}"/>
          <text x="126" y="396" class="variant" :class="{ active: activeScheme === 'a' || activeScheme === 'c' }">Thermal properties</text>
          <path d="M264 388L312 388" :class="{ active: activeScheme === 'a' || activeScheme === 'c' }" />
          <rect x="2" y="418" width="262" height="36" class="variant" :class="{ active: activeScheme === 'c'}"/>
          <text x="126" y="442" class="variant" :class="{ active: activeScheme === 'c'}">Hygroscopicity</text>
          <path d="M264 435L312 435" :class="{ active: activeScheme === 'c'}" />
          <rect x="2" y="465" width="262" height="36" class="variant" :class="{ active: activeScheme === 'a' || activeScheme === 'c' }"/>
          <text x="126" y="488" class="variant" :class="{ active: activeScheme === 'a' || activeScheme === 'c' }">Air distribution</text>
          <path d="M264 482L312 482" :class="{ active: activeScheme === 'a' || activeScheme === 'c' }" />
          <rect x="2" y="512" width="262" height="36" class="variant" :class="{ active: activeScheme === 'a' }"/>
          <text x="126" y="536" class="variant" :class="{ active: activeScheme === 'a'}">Hypoallergenicity</text>
          <path d="M264 530L312 530" :class="{ active: activeScheme === 'a'}" />
          <path d="M312 482L312 530" :class="{ active: activeScheme === 'a'}"/>
          <path d="M312 341L312 388" :class="{ active: activeScheme === 'a' || activeScheme === 'c' }"/>
          <path d="M312 388L312 435" :class="{ active: activeScheme === 'a' || activeScheme === 'c' }"/>
          <path d="M312 435L312 482" :class="{ active: activeScheme === 'a' || activeScheme === 'c' }"/>
        </svg>
      </div>
      <div class="scheme desktop-only">
        <svg viewBox="0 86 1068 282" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="160" y="90" width="196" height="71" class="selector" :class="{ 'active': activeScheme === 'a'}"/>
          <text @click="changeScheme('a')" x="258" y="130" class="selector" :class="{ 'active': activeScheme === 'a'}">Material and filler</text>
          <path d="M256 160V209H528" :class="{ 'active': activeScheme === 'a'}" />
          <rect x="487" y="90" width="156" height="70" class="selector" :class="{ 'active': activeScheme === 'b'}"/>
          <text @click="changeScheme('b')" x="566" y="130" class="selector" :class="{ 'active': activeScheme === 'b'}">Structure</text>
          <path d="M568 160 V 180" :class="{ 'active': activeScheme === 'b' }"/>
          <rect x="748" y="90" width="261" height="70" class="selector" :class="{ 'active': activeScheme === 'c'}"/>
          <text @click="changeScheme('c')" x="880" y="130" class="selector" :class="{ 'active': activeScheme === 'c'}">Processing</text>
          <path d="M874 160 V 209H 607" :class="{ 'active': activeScheme === 'c'}" />
          <path d="M570.857 203.327L581.779 221.319H560.91L558.672 225H588V224.089L575.521 203.459L587.221 185.918V185H551V188.689H580.668L570.857 203.327Z" fill="#0F1319"/>
          <path d="M558.637 225.107L558.823 224.811L559.001 224.515L559.304 224.041L560.702 221.8L560.749 221.714L560.935 221.419L566.611 212.323L556.524 197.688H575.137L575.285 197.478L577.009 194.973L577.211 194.677L577.413 194.381L577.677 194H549.8V194.918L561.82 212.456L549.047 233.012L549 233.082V234H581V230.312H555.391L558.404 225.48L558.637 225.107Z" fill="#907C4B"/>
          <path d="M568 240 V 260" class="active" />
          <path d="M569 260 L 348 260" class="active"/>
          <path d="M568 260 L 764 260" :class="{ 'active': activeScheme === 'a' || activeScheme === 'c' }"/>
          <rect x="2" y="310" width="173" height="56" class="variant" :class="{ 'active': activeScheme === 'b'}"/>
          <text x="88" y="344" class="variant" :class="{ 'active': activeScheme === 'b'}">Anatomic properties</text>
          <path d="M106 310 L 106 260 L 348 260" :class="{ 'active': activeScheme === 'b'}"/>
          <rect x="242" y="310" width="162" height="56" class="variant" :class="{ 'active': activeScheme === 'a' || activeScheme === 'c'}"/>
          <text x="324" y="344" class="variant" :class="{ 'active': activeScheme === 'a' || activeScheme === 'c'}">Thermal properties</text>
          <path d="M348 260 L 348 310" :class="{ 'active': activeScheme === 'a' || activeScheme === 'c'}"/>
          <rect x="486" y="310" width="134" height="56" class="variant" :class="{ 'active': activeScheme === 'c'}"/>
          <text x="552" y="344" class="variant" :class="{ 'active': activeScheme === 'c'}">Hygroscopicity</text>
          <path d="M568 260 L 568 310" :class="{ 'active': activeScheme === 'c'}"/>
          <rect x="682" y="310" width="135" height="56" class="variant" :class="{ 'active': activeScheme === 'a' || activeScheme === 'c'}"/>
          <text x="748" y="344" class="variant" :class="{ 'active': activeScheme === 'a' || activeScheme === 'c'}">Air distribution</text>
          <path d="M764 260 L 764 310" :class="{ 'active': activeScheme === 'a' || activeScheme === 'c'}"/>
          <rect x="916" y="310" width="150" height="56" class="variant" :class="{ 'active': activeScheme === 'a'}"/>
          <text x="992" y="344" class="variant" :class="{ 'active': activeScheme === 'a'}">Hypoallergenicity</text>
          <path d="M1004 260 L 1004 310 M764 260 L 1004 260" :class="{ 'active': activeScheme === 'a'}"/>
        </svg>
      </div>
      <div class="content">
        <p
          v-if="activeScheme === 'a'"
          class="scheme-text text"
        >Covers, fillers, inserts, and layers of Sleep System Togas Innovations products are made from various traditional and innovative materials, from cotton to Sensotex®, from down to Climafiber®. The choice of materials affects tactility, the degree of rigidity, and temperature properties</p>
        <p
          v-if="activeScheme === 'b'"
          class="scheme-text text">A complex technological system keeps the body in the correct anatomical position and ensures a healthy and relaxing sleep. The structure precisely reacts to all movements and helps to create comfortable conditions for the sleeping person</p>
        <p
          v-if="activeScheme === 'c'"
          class="scheme-text text"
        >The special treatment makes the sleep products more durable and less wrinkled. The Kefalis solution is based on algae extract from the Ionian Sea and affects the hygroscopicity, breathability, and thermal properties</p>

        <h2 class="title system">A unified system</h2>
        <hr class="border" />
        <p class="text">Yes, that is a system. Only the right combination of products can provide a high-quality and healthy sleep. Everything is important: a bed, a mattress, a mattress pad, a pillow, a comforter and, of course, bed linens. All of these need to be selected in accordance with the individual characteristics of the sleeper.</p>
      </div>
      <div class="unified-system grid">
        <div class="unified-system__text col-12 col-m-5">
          <SfAccordion :show-chevron="true" class="unified-system__accordion">
            <SfAccordionItem
              class="accordion__item"
              header="Pillows"
            >
              <template>
                <span>The support for the anatomically correct posture of the neck while sleeping</span>
              </template>
            </SfAccordionItem>
            <SfAccordionItem
              class="accordion__item"
              header="Duvets"
            >
              <template>
                <span class="text">Creating the perfect microclimate during your sleep</span>
              </template>
            </SfAccordionItem>
            <SfAccordionItem
              class="accordion__item"
              header="Mattress Pads"
            >
              <template>
                <span class="text">Additional support for weight distribution of the body parts that are in light contact with the surface of the mattress</span>
              </template>
            </SfAccordionItem>
          </SfAccordion>
        </div>
        <picture class="unified-system__image col-12 col-m-7">
          <source
            media="screen and (min-width: 1024px)"
            srcset="~/static/sleep-system/6-d.webp"
          />
          <img
            class="img-block"
            src="~/static/sleep-system/6-m.webp"
            alt="Sleep System"
          >
        </picture>
      </div>
    </div>
    <div class="sleep-system__block7">
      <picture>
        <source
          media="screen and (min-width: 1024px)"
          srcset="~/static/sleep-system/7-d.webp"
        />
        <img
          class="img-block"
          src="~/static/sleep-system/7-m.webp"
          alt="Sleep System"
        >
      </picture>
      <div class="content --absolute grid">
        <div class="col-m-6 --flex --v-center">
          <h2 class="title">Choose the combination</h2>
          <p class="text --underlined">All the products of the Togas Innovations Sleep System are created in a R&D center of the same name in Athens. All the products go through various testing processes. We are not only talking about the individual tests. We also test the way they interact with other products from the Sleep System collection. We run tests to define the optimal fabric combinations and fillers, mechanical interactions, and the dependence of the degree of wear of the product while in contact with others.</p>
          <SfIcon
            class="qc-logo" icon="logo_QC" />
          <p class="text --gold">This is how we make further changes to achieve the maximum effect, by using all the categories of products of the Sleep System.</p>
          <p class="text">We involve various engineering agencies to work together on individual elements of our products, depending on their type. For example, an independent T-Spring MZ spring mattress block is the result of the collaboration with one of the leading Bavarian engineering agencies that work with metal products in the automotive industry.</p>
          <div class="icons">
            <SfIcon
              class="icon --sensotex"
              icon="logo_sensotex_basecore"
            />
            <SfIcon
              class="icon --helin"
              icon="logo_helin_ecolayers"
            />
            <SfIcon
              class="icon --tspring"
              icon="logo_t_spring_mz"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="sleep-system__block8">
      <picture>
        <source
          media="screen and (min-width: 1024px)"
          srcset="~/static/sleep-system/8-d.webp"
        />
        <img
          class="img-block"
          src="~/static/sleep-system/8-m.webp"
          alt="Sleep System"
        >
      </picture>
      <div class="content --absolute grid">
        <div class="col-m-5 --flex --v-bottom">
          <h2 class="title">A healthy sleep means a strong immunity</h2>
          <p class="text --underlined">It has been scientifically proven that in our sleep our muscles are restored, and the immune system is strengthened. According to research, a person who has received enough sleep is less prone to illness after contact with bacteria. During sleep, the skin elasticity restores due to the blood and oxygen flow, and the neurons responsible for your memory get restored as well.</p>
          <p class="text">All that is required from us is to provide ourselves with everything that is needed for a healthy sleep: a comfortable pillow, a soft and cozy blanket, and a comfortable mattress.</p>
        </div>
      </div>
    </div>
    <div class="sleep-system__block9">
      <div class="grid">
        <SfLink
          class="col-12 col-m-4"
          :link="decodeURIComponent(localePath('/pillows.html'))"
        >
          <picture>
            <source
              media="screen and (min-width: 1024px)"
              srcset="~/static/sleep-system/9-1-d.webp"
            />
            <img
              class="img-block"
              src="~/static/sleep-system/9-1-m.webp"
              alt="Sleep System"
            >
          </picture>
          <span class="text">Pillows</span>
        </SfLink>
        <SfLink
          class="col-12 col-m-4"
          :link="decodeURIComponent(localePath('/duvets.html'))"
        >
          <picture>
            <source
              media="screen and (min-width: 1024px)"
              srcset="~/static/sleep-system/9-2-d.webp"
            />
            <img
              class="img-block"
              src="~/static/sleep-system/9-2-m.webp"
              alt="Sleep System"
            >
          </picture>
          <span class="text">Duvets</span>
        </SfLink>
        <SfLink
          class="col-12 col-m-4"
          :link="decodeURIComponent(localePath('/bed-linen/mattress-pads.html'))"
        >
          <picture>
            <source
              media="screen and (min-width: 1024px)"
              srcset="~/static/sleep-system/9-3-d.webp"
            />
            <img
              class="img-block"
              src="~/static/sleep-system/9-3-m.webp"
              alt="Sleep System"
            >
          </picture>
          <span class="text">Mattress Pads</span>
        </SfLink>
      </div>
    </div>
    <div class="sleep-system__block10">
      <div v-if="!loadingQuizzes" class="grid">
        <SfLink
          v-for="(quiz, i) in quizItems" :key="i"
          class="col-12 col-m-6"
          :link="decodeURIComponent(localePath(`/${quiz.url_path}`))"
        >
          <picture>
            <source
              media="screen and (min-width: 1024px)"
              :srcset="quiz.thumbnail_image_desktop_url"
            />
            <img
              class="img-block"
              :src="quiz.thumbnail_image_mobile_url"
              alt="Sleep System"
            >
          </picture>
          <div class="text-content" :class="{ '--right': i%2 === 0 }">
            <h3 :class="{ '--right': i%2 === 0 }" v-html="quiz.title" />
            <p v-html="quiz.subtitle" />
          </div>
        </SfLink>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, useFetch, ref } from '@nuxtjs/composition-api';

import {
  SfAccordion,
  SfIcon,
  SfLink,
} from '~/components';

import { useApi } from '~/composables';
import quizzesQuery from '~/modules/quiz/queries/getQuizzesByIds';

export default defineComponent({
  name: 'SleepSystem',
  components: {
    SfAccordion,
    SfLink,
    SfIcon
  },
  head() {
    return {
      title: 'Sleep System',
    }
  },
  setup() {
    const { query } = useApi();

    const activeScheme = ref('a');
    const changeScheme = (variant) => activeScheme.value = variant;

    const loadingQuizzes = ref(true);
    const quizItems = ref();
    useFetch(async () => {
      try {
        const { data } = await query(quizzesQuery, { quizIds: ['choose-pillow', 'choose-duvet']});
        quizItems.value = data.productRecommendationQuizzes?.items;
      } catch (error) {
        console.error('Error: ', error);
      } finally {
        loadingQuizzes.value = false;
      }
    })

    return {
      activeScheme,
      changeScheme,
      quizItems,
      loadingQuizzes
    }
  },
});
</script>

<style lang="scss" scoped>
.sleep-system {
  > div {
    position: relative;
  }

    .--absolute {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .--flex {
      display: flex;
      flex-direction: column;
    }

    .--center {
      align-items: center;
    }

  .--v-bottom {
    justify-content: flex-end;
  }

    .--underlined {
      display: inline-block;
      border-top: 1px solid;
      padding-top: var(--spacer-sm);
      margin-top: var(--spacer-sm);
    }

  .--white {
    color: var(--togas-white-color);
  }

  .--gold {
    color: var(--togas-gold-color);
  }

  &__logo {
    --icon-height: 78px;
    --icon-width: 158px;
    margin-bottom: var(--spacer-sm);
  }

  .content {
    padding: var(--spacer-lg) 20px;

    .title {
      font: var(--font-weight--normal) var(--font-size--xl) / var(--line-height--xl) var(--font-family--secondary);
      text-transform: uppercase;
    }

    .text {
      font: var(--font-size--lg) / var(--line-height--lg) var(--font-family--primary);
    }
  }

  .text:last-child {
    margin-bottom: 0;
  }

  .qc-logo {
    --icon-height: 56px;
    --icon-width: 278px;
    margin-bottom: var(--spacer-base);
  }

  .icons {
    display: flex;
    align-items: center;
    gap: var(--spacer-base);

    .--sensotex {
      --icon-height: 98px;
      --icon-width: 90px;
    }

    .--helin {
      --icon-height: 100px;
      --icon-width: 64px;
    }

    .--tspring {
      --icon-height: 75px;
      --icon-width: 140px;
    }
  }

  &__block1 {
    top: calc(-1 * var(--header-height));
    margin-bottom: calc(-1 * var(--header-height));

    .content {
      padding: 128px var(--spacer-base) var(--spacer-base);

      .title {
        font: var(--font-weight--medium) var(--font-size--xl) / var(--line-height--xl) var(--font-family--primary);
        text-align: center;
        text-transform: uppercase;
        margin-bottom: var(--spacer-sm);
      }

      .links {
        display: flex;

        > a {
          font: var(--font-size--lg) / 20px var(--font-family--primary);
          padding: 0 10px;
          --link-text-decoration: none;

          &:not(:last-child) {
            border-right: 2px solid rgba(255, 255, 255, .3);
          }
        }
      }
    }
  }

  &__block6 {

    .title {
      padding-bottom: var(--spacer-sm);
      margin-bottom: var(--spacer-sm);
      border-bottom: 1px solid var(--togas-grey-400-color);

      &.system {
        margin-top: 60px;
      }
    }

    .unified-system {

      &__accordion {
        --accordion-item-open-header-color: var(--togas-gold-color);
        --accordion-item-header-font: 22px / 26px var(--font-family--secondary);
        --accordion-item-header-text-transform: uppercase;
        --accordion-item-content-font: var(--font-size--lg) / var(--line-height--lg) var(--font-family--primary);
        --accordion-item-content-border-width: 1px 0 1px 0;
      }

      &__image {
        margin-top: 40px;
      }
    }

    .border {
      display: none;
    }

    .accordion {
      padding-top: var(--spacer-xs);
    }
  }

  &__block9 {
    margin: 40px 20px;

    .grid {
      grid-gap: var(--spacer-base);
      text-align: center;
    }

    .text {
      display: inline-block;
      font: 24px / 28px var(--font-family--secondary);
      text-transform: uppercase;
      text-decoration: none;
      margin-top: 10px;
    }
  }

  &__block10 {
    margin: 40px 20px;

    .sf-link {
      position: relative;
    }

    .text-content {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: var(--spacer-base);
    }

    .grid {
      grid-gap: var(--spacer-base);
    }

    h3 {
      font: var(--font-weight--normal) var(--font-size--xl) / 28px var(--font-family--secondary);
      text-align: center;
      text-transform: uppercase;
      width: min-content;

      &.--left {
        margin-right: auto;
      }

      &.--right {
        margin-left: auto;
      }
    }

    p {
      font: var(--font-size--lg) / var(--line-height--lg) var(--font-family--primary);
      text-align: center;
      margin-top: auto;
    }
  }

  .scheme {
    padding: 0 20px;

    rect {
      stroke-width: 1px;
      stroke: var(--togas-grey-400-color);
      transition: all .3s;

      &.active {
        stroke-width: 2px;

        &.selector {
          fill: var(--togas-gold-color);
          stroke: var(--togas-gold-color);
        }

        &.variant {
          stroke: var(--togas-gold-color);
        }
      }
    }

    text {
      font-family: var(--font-family--primary);
      font-size: var(--font-size--lg);
      fill: var(--togas-grey-400-color);
      text-anchor: middle;
      transition: all .3s;
      user-select: none;

      &.selector {
        cursor: pointer;
      }

      &.active {

        &.selector {
          fill: var(--togas-white-color);
          cursor: initial;
        }

        &.variant {
          fill: var(--togas-gold-color);
        }
      }
    }

    path {
      stroke: var(--togas-grey-400-color);
      stroke-width: 1px;
      transition: all .3s;

      &.active {
        stroke: var(--togas-gold-color);
        stroke-width: 2px;
      }
    }
  }

  @include for-desktop {

    .--v-center {
      justify-content: center;
    }

    &__logo {
      --icon-height: 128px;
      --icon-width: 264px;
      margin-bottom: var(--spacer-base);
    }

    .content {
      .title {
        font: var(--font-weight--normal) 28px / var(--line-height--xxl) var(--font-family--secondary);
      }

      .text {
        font:  var(--font-size--lg) / var(--line-height--lg) var(--font-family--primary);
      }
    }

    .qc-logo {
      --icon-height: 52px;
      --icon-width: 260px;
      margin-bottom: 60px;
    }

    .icons {
      gap: 60px;

      .--sensotex {
        --icon-height: 92px;
        --icon-width: 84px;
      }

      .--helin {
        --icon-height: 92px;
        --icon-width: 60px;
      }

      .--tspring {
        --icon-height: 70px;
        --icon-width: 131px;
      }
    }

    &__block1 {
      top: calc(-1 * var(--header-height));
      margin-bottom: calc(-1 * var(--header-height));

      .content {

        .title {
          font: var(--font-weight--medium) 28px / var(--line-height--xxl) var(--font-family--primary);
          margin-bottom: var(--spacer-base);
        }

        .links {
          > a {
            padding: 0 var(--spacer-sm);
          }
        }
      }
    }

    &__block2 {
      .content {
        padding-right: 100px;
      }
    }

    &__block6 {

      .unified-system {
        margin: 0 60px;

        &__image {
          order: -1;
          margin-top: 0;
        }

        &__text {
          align-self: center;
        }
      }

      .content {
        text-align: center;
        max-width: 740px;
        margin: 50px auto 0;
      }

      .title {
        border: none;
        padding-bottom: 0;
      }

      .border {
        display: block;
        width: 450px;
        border: none;
        color: var(--togas-black-color);
        background-color: var(--togas-black-color);
        height: 1px;
        margin: var(--spacer-sm) auto;
      }

      .text {
        border: none;
        padding-top: 0;
        margin-top: 0;
      }

      .scheme-text {
        margin-bottom: 60px;
      }

      .accordion {
        padding-top: 0;
        margin-bottom: 0;

        &__item {
          margin-bottom: 40px;
        }
      }
    }

    &__block9 {
      margin: 80px 120px 40px;

      .grid {
        grid-gap: var(--spacer-xl);
      }

      .text {
        font: 28px / 32px var(--font-family--secondary);
        margin-top: 20px;
      }
    }

    &__block10 {
      margin: 40px 120px 80px;

      .grid {
        grid-gap: 40px;
      }

      .text-content {
        justify-content: center;
        width: 64%;

        &.--right {
          margin-left: auto;
        }
      }

      h3 {
        width: initial;
        margin-bottom: var(--spacer-base);

        &.--right {
          margin-left: initial;
        }
      }

      p {
        margin-top: 0;
      }
    }

    .scheme {
      padding: 0;
      margin: 0 150px 30px;
    }

    &__block3,
    &__block7,
    &__block8 {
      .content {
        padding: 60px 150px;
      }
    }
  }
}
</style>
